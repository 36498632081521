import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Layout from '../layout/Layout'
import { Link } from 'react-router-dom'
import MetaContainer from '../../seo/MetaContainer'

const PrivacyPolicy = () => {

    return (
        <Layout>
            <MetaContainer
                title='Privacy Policy - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/privacy-policy'
            />
            <Container maxWidth="xl">
                <Box sx={{
                    overflowX:'hidden',
                    maxWidth: '1100px',
                    margin: '0 auto',
                    fontFamily: 'Poppins',
                    padding: { xl: '50px 0px', lg: '50px 0px', md: '25px 0px', sm: '20px 0px', xs: '20px 0px' },
                }}>
                    <Box>
                        <Typography sx={{
                            padding: '0px 0 15px 0',
                            color: '#000',
                            fontWeight: 400,
                            fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                        }}>This Privacy Policy applies to all personal information collected by Discoverr.AI Pty Ltd (ABN: 50 640 133 259) via the website located at Brang.com.au and the Brang Customer and Branger App.</Typography>
                    </Box>
                    <Box sx={{
                        margin: '0px 0px 15px 0px',
                        padding: 0,
                    }}>
                        <Typography sx={{
                            padding: '0px 0 6px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>1. What is “personal information”?</Typography>
                        <List sx={{
                            padding: '0px 0px 0px 0px',
                            margin: '0px',
                        }}>
                            <ListItem sx={{
                                margin: '0px',
                                padding: '0px 16px'
                            }}>
                                <ListItemText>
                                    <Typography sx={{ fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' }, fontWeight: '700', }}> (A) The Privacy Act 1988 (Cth) currently defines “personal information” as meaning information or an opinion about an identified individual or an individual who is reasonably identifiable:</Typography>

                                    <List sx={{
                                        margin: '0px', padding: "0px",
                                    }}>

                                        <ListItem sx={{
                                            padding: '0px 0px 0px 0px',
                                            "& span": {
                                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                                fontWeight: '400'
                                            }
                                        }}>
                                            <ListItemText sx={{ paddingLeft: "20px" }}>
                                                (i) whether the information or opinion is true or not; and
                                            </ListItemText>
                                        </ListItem>


                                        <ListItem sx={{
                                            padding: '0px 0px 0px 0px',
                                            "& span": {
                                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                                fontWeight: '400'
                                            }
                                        }}>
                                            <ListItemText sx={{ paddingLeft: "20px" }}>
                                                (ii) whether the information or opinion is recorded in a material form or not.
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </ListItemText>
                            </ListItem>

                            <ListItem sx={{ padding: '0px 16px' }} >
                                <Typography sx={{ fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' }, fontWeight: '700', margin: "0" }}>(B) If information does not disclose your identity or enable your identity to be ascertained, it will in most cases not be classified as “personal information” and will not be subject to this privacy policy.</Typography>
                            </ListItem>

                        </List>
                    </Box>
                    <Box sx={{ marginBottom: '11px' }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>2. What information do we collect?</Typography>
                        <Typography sx={{ fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' }, fontWeight: '700' }}>
                            The kind of personal information that we collect from you will depend on how you use the website/app. The personal information which We collect and hold about you may include:</Typography>


                        <List sx={{
                            margin: 0, paddingRight: 0, marginLeft: '40px', padding: "0", "& .MuiListItem-root": {
                                listStyle: "disc"
                            },
                            "& span": {
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                fontWeight: '500', padding: "0px"
                            }
                        }}>
                            <ListItem sx={{
                                margin: 0, display: 'list-item', padding: { xs: '0px' }

                            }}>
                                <ListItemText primary="Information about your device and web browser;" />
                            </ListItem>

                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: '0px', } }}>
                                <ListItemText primary="IP Address;" />
                            </ListItem>

                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: '0px' } }}>
                                <ListItemText primary="Time Zone;" />
                            </ListItem>

                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: '0px' } }}>
                                <ListItemText primary="Cookies;" />
                            </ListItem>

                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: '0px' } }}>
                                <ListItemText primary="Information about web pages and products viewed;" />
                            </ListItem>

                        </List>

                        <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>Additionally as part of the registration process on the Site, we collect certain information from you, including your name, age gender, occupation, education, billing address, shipping address, payment information, email address, alternative email address and phone number. We refer to this information as “Customer & Order Information”.</Typography>
                    </Box>

                    <Box sx={{
                        marginBottom: '11px',
                        "& span": {
                            fontWeight: '500',
                            fontSize: { xl: '17px', lg: '17px', md: '17px', sm: '14px', xs: '14px' },
                        }
                    }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>3. How we collect your personal information</Typography>
                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (A) We may collect personal information from you whenever you input such information into the website/app.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (B) We also collect cookies from your computer which enable us to tell when you use the website/app and also to help customise your website/app experience. As a general rule, however, it is not possible to identify you personally from our use of cookies.
                            </Typography>
                        </ListItem>
                    </Box>

                    <Box sx={{
                        marginBottom: '11px', wordWrap: "break-word",
                        "& span": {
                            fontSize: { xl: '17px', lg: '17px', md: '17px', sm: '14px', xs: '14px' }, fontWeight: '500'
                        }
                    }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '20px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>4. Purpose of collection</Typography>
                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (A) The purpose for which we collect personal information is to provide you with the best service experience possible on the website/app.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (B) We customarily disclose personal information only to our service providers, business associates and partners who assist us in operating the website/app. Your personal information may also be exposed from time to time to maintenance and support personnel acting in the normal course of their duties.
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (c) Information about Customers on an aggregate (excluding any information that may identify you specifically) covering Customer transaction data and Customer demographic and location data may be provided to partners of Discoverr.AI for the purpose of creating additional features on the website/app, creating appropriate merchandising or creating new products and services and conducting marketing research and statistical analysis of customer behaviour and transactions.
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (d) By using our website/app, you consent to the receipt of direct marketing material. We will only use your personal information for this purpose if we have collected such information direct from you, and if it is material of a type which you would reasonably expect to receive from use. We do not use sensitive personal information in direct marketing activity. Our direct marketing material will include a simple means by which you can request not to receive further communications of this nature.
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ padding: '0px 16px' }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (e) We will also use this information to serve various promotional and advertising materials to you via display advertisements through the Google Ad network on third party websites. You can opt out of Google Analytics for Display Advertising and customize Google Display network ads using the Ads Preferences Manager.
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ padding: '0px 16px', }} >
                            <Typography sx={{ fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "4px 4px" }}>
                                (f) We share your Personal Information with third parties to help us use your Personal Information, as described above. We also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: <Link target='_blank' to="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/.</Link> You can also opt-out of Google Analytics here: <Link target='_blank' to="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout.</Link>
                            </Typography>
                        </ListItem>
                    </Box>

                    <Box sx={{ marginBottom: '15px' }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>5. Access and correction</Typography>
                        <Typography sx={{ fontSize: { lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "0px 4px", padding: "0px 16px" }}>Australian Privacy Principle 12 permits you to obtain access to the personal information we hold about you in certain circumstances, and Australian Privacy Principle 13 allows you to correct inaccurate personal information subject to certain exceptions. If you would like to obtain such access, please contact us as set out below.</Typography>
                    </Box>

                    <Box sx={{ marginBottom: '15px' }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '20px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>6. Complaint procedure</Typography>
                        <Typography sx={{ fontSize: { lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "0px 4px", padding: "0px 16px" }}>If you have a complaint concerning the manner in which we maintain the privacy of your personal information, please contact us as set out below. All complaints will be considered by our privacy officer and we may seek further information from you to clarify your concerns. If we agree that your complaint is well founded, we will, in consultation with you, take appropriate steps to rectify the problem. If you remain dissatisfied with the outcome, you may refer the matter to the Office of the Australian Information Commissioner.</Typography>
                    </Box>


                    <Box sx={{ marginBottom: '15px' }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '20px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>7. Overseas transfer</Typography>
                        <Typography sx={{ fontSize: { lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "0px 4px", padding: "0px 16px" }}>Your personal information may be transferred overseas or stored overseas for a variety of reasons. It is not possible to identify each and every country to which your personal information may be sent. If your personal information is sent to a recipient in a country with data protection laws which are at least substantially similar to the Australian Privacy Principles, and where there are mechanisms available to you to enforce protection of your personal information under that overseas law, we will not be liable for a breach of the Australian Privacy Principles if your personal information is mishandled in that jurisdiction. If your personal information is transferred to a jurisdiction which does not have data protection laws as comprehensive as Australia's, we will take reasonable steps to secure a contractual commitment from the recipient to handle your information in accordance with the Australian Privacy Principles.</Typography>
                    </Box>


                    <Box sx={{ marginBottom: '15px' }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '20px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>8. GDPR</Typography>
                        <Typography sx={{ fontSize: { lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "0px 4px", padding: "0px 16px" }}>In some circumstances, the European Union General Data Protection Regulation (GDPR) provides additional protection to individuals located in Europe. The fact that you may be located in Europe does not, however, on its own entitle you to protection under the GDPR. Our website/app does not specifically target customers located in the European Union and we do not monitor the behavior of individuals in the European Union, and accordingly the GDPR does not apply.</Typography>
                    </Box>

                    <Box sx={{ marginBottom: '15px' }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '20px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>9. Location Information</Typography>
                        <Typography sx={{ fontSize: { lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "0px 4px", padding: "0px 16px" }} >To participate as a BRANG agent, you must permit the BRANG Services to access location services through the permission system used by your mobile operating system (“Platform”) or browser. We may collect the precise location of your device when the BRANG app is running in the foreground or background of your device. We may also derive your approximate location from your IP address. We use your location information to verify that you are present in your preferred region or city when you begin or engage in a delivery through the Discoverr.AI Pty Ltd Services (a “Delivery”), connect you with delivery opportunities in your zone, and track the progress and completion of your Deliveries. You can enable the location tracking feature through the settings on your device or Platform or when prompted by the BRANG mobile app. If you choose to disable the location feature through the settings on your device or Platform,Discoverr.AI Pty Ltd will not receive precise location information from your device, which will prevent you from being able to Trip and receiving delivery opportunities in your area.</Typography>
                    </Box>

                    <Box sx={{
                        marginBottom: '15px', "& a": {
                            cursor: 'pointer',
                            fontWeight: 500,
                            textDecoration: "none"
                        }
                    }}>
                        <Typography sx={{
                            padding: '0px 0 10px 0',
                            color: '#000',
                            fontWeight: 700,
                            fontSize: { xl: '20px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                            textTransform:'uppercase',
                        }}>10. How to contact us about privacy</Typography>
                        <Typography sx={{ fontSize: { lg: '16px', md: '14px', sm: '14px', xs: '14px' }, fontWeight: '500', margin: "0px 4px", padding: "0px 16px" }}>If you have any queries, or if you seek access to your personal information, or if you have a complaint about our privacy practices, you can contact us at <Link to="mailto:support@Brang.com.au">support@Brang.com.au</Link> or <Link to='tel:1300 027 264'>13000BRANG</Link></Typography>
                    </Box>
                </Box>
            </Container>
        </Layout >
    )
}

export default PrivacyPolicy
