import React from 'react'
import Layout from '../layout/Layout'
import { Box, Container, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import styled from '@emotion/styled';
import MetaContainer from '../../seo/MetaContainer';

const TermsConditions = () => {
    return (
        <Layout>
            <MetaContainer
                title='Terms & Conditions - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/terms-conditions'
            />
            <Container maxWidth="xl" ><Box sx={{
                maxWidth: '1100px',
                margin: '0 auto',
                overflowX:'hidden',
                padding: { xl: '50px 0px', lg: '50px 0px', md: '25px 0px', sm: '20px 0px', xs: '20px 0px' },
            }}>
                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                        lineHeight: "1.5",
                        color: "#333333",
                        textAlign: "justify",
                    }}>
                        Discoverr.AI Pty Ltd (ABN: 50 640 133 259) (Discoverr.AI) owns and operates this Website/App. Access to and use of this Website/App and services available through this Website/App (collectively, Services) is subject to the following terms, conditions, and notices <strong>(Terms and Conditions)</strong>. By entering this agreement, you acknowledge that Discoverr.AI Pty Ltd does not provide any delivery or transportation services. By using the <strong>Services</strong>, you are agreeing to all of the Terms and Conditions, as may be updated by us from time to time. You should check this page regularly to take notice of any changes we may have made to the Terms and Conditions. If you do not agree to these Terms of Use, then you must not use this website or the Services.
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>1. BRANG IS A DELIVERY SERVICE?</Typography>

                    <List sx={{
                        margin: 0, paddingTop: "0", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            fontWeight: '400', padding: "0px",
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{ margin: 0 }} primary="By entering this agreement, you acknowledge that Discoverr.AI and the Brang website/app is a delivery service. Discoverr.AI provides technological services that empowers merchants to get same-day deliveries for their businesses through our delivery drivers." />
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>2. SERVICES</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                        // "& span": {
                        //     fontSize: '16px', fontWeight: '500', padding: "0px"
                        // }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', listStyle:'circle', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    fontWeight: '700', padding: "0px",
                                }, margin: 0
                            }} primary="Discoverr.AI will provide the following services to you:" />

                            <List sx={{
                                margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    color: "#333333"
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="The Brang website;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="The Brang mobile app;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="The Brang Merchant Portal." />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                        // "& span": {
                        //     fontSize: '16px', fontWeight: '500', padding: "0px"
                        // }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', listStyle: "circle", padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    fontWeight: '700', padding: "0px"
                                }, margin: 0
                            }} primary="The Services will enable you to:" />

                            <List sx={{
                                margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    color: "#333333"
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Connect with our Delivery Drivers (Brangers)." />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>3. SCOPE OF THE SERVICES</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                        // "& span": {
                        //     fontSize: "14px",
                        //     marginBottom: "10px",
                        //     lineHeight: 1.8,
                        //     color: "#333333"
                        // }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    fontWeight: '700', padding: "0px"
                                }
                            }} primary="As part of the services contained in clause 2(a) Discoverr.AI will:" />

                            <List sx={{
                                margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Provide technology services via the Brang website/app;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Provide complaint handling mechanisms;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Provide refunding services (within 5-10 working days);" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Connect you with Delivery Drivers via the Brang website/app/s." />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>

                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>4. MANAGEMENT OF THE BRANG WEBSITE/APP</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                            color: "#333333"
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Discoverr.AI reserves the right to amend these Terms and Conditions from time to time. Amendments will be effective immediately upon notification on this Website/App. Your continued use of the Website/App following such notification will represent an agreement by you to be bound by the Terms and Conditions as amended." />
                        </ListItem>


                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Access to this Website/App is permitted on a temporary basis, and we reserve the right to withdraw or amend the Services without notice. We will not be liable if for any reason this Website/App is unavailable at any time or for any period. From time to time, we may restrict access to some parts or all of this Website/App." />
                        </ListItem>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="This Website/App may contain links to other websites (Linked Sites), which are not operated by Discoverr.AI. Discoverr.AI has no control over the Linked Sites and accepts no responsibility for them or for any loss or damage that may arise from your use of them. Your use of the Linked Sites will be subject to the Terms and Conditions and service contained within each such site." />
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>5. CONFIDENTIAL INFORMATION</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                            color: "#333333"
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You acknowledge that all material provided to you by Discoverr.AI is confidential information owned by Discoverr.AI and you must not disclose it to any third party." />
                        </ListItem>


                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="The information referred to in clause 5(a) does not include information that is already in the public domain." />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>6. YOUR OBLIGATIONS</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                            color: "#333333"
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must comply with all Discoverr.AI policies and varied from time to time." />
                        </ListItem>


                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must comply with all applicable laws and regulations." />
                        </ListItem>
                    </List>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            // fontSize: '16px', fontWeight: '500', padding: "0px"
                        }
                    }}>
                        <ListItemText sx={{
                            "& span": {
                                fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                fontWeight: '700', padding: "0px",
                            }
                        }} primary="You must inform Discoverr.AI of any relevant changes including:" />
                        <ListItem sx={{ marginLeft: "40px", fontWeight: '400', display: 'list-item', padding: { xs: "0" }, color: "#333333" }}>
                            <ListItemText primary="Changes to your location;" />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>7. DELIVERY</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                        "& span": {
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            fontWeight: '700', padding: "0px",
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" }, }}>
                            <ListItemText sx={{
                                '& span': {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                },
                                margin: 0
                            }} primary="When using the Brang website/app:" />
                        </ListItem>

                        <List sx={{
                            margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                                listStyle: "disc"
                            },
                            "& span": {
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                fontWeight: '400', padding: "0px", lineHeight: 1.8,
                            }
                        }}>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText sx={{ color: "#333333" }} primary="Your Delivery Partner is engaged by you, as your agent using our platform to deliver your purchased Goods to you." />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText sx={{ color: "#333333" }} primary="Your Delivery Partner will at all times be under your control;" />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText sx={{ color: "#333333" }} primary="You, maintain title to all goods until the good is delivered by your Delivery Partner." />
                            </ListItem>
                        </List>
                    </List>
                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                        "& span": {
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            marginBottom: "10px",
                            lineHeight: 1.8,
                            color: "#333333"
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Neither the customer nor the registered driver (delivery partner) should be engaging directly for a period of 6 months from the last delivery completed using our platform. This may result in breach of code with a legal action" />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>8. GOODS</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must ensure all Goods comply with any applicable laws." />
                        </ListItem>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must ensure that all Goods comply with all Discoverr.AI policies." />
                        </ListItem>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must ensure to notify us straight away in case of any loss or damage to goods received. After a reasonable investigation if found out that the loss or damage is the fault of your delivery partner, Discoverr.AI will cover upto AUD 300 for the loss or damage. You must do all things reasonably necessary to assist in the investigation." />
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '18px', sm: '15px', xs: '15px' },
                    }}>9. FEES</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must pay the applicable Delivery Fee, which is calculated based on the distance, type of vehicle and delivery times." />
                        </ListItem>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="The total applicable fees are available to you prior to making the delivery booking." />
                        </ListItem>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="The Delivery Fee will be collected by Discoverr.AI and be remitted to your Delivery Partner, after Discoverr.AI takes a portion of the fee for allowing you to use it’s technological Services (the Brang Fee)." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="The Delivery Fee will be collected by Discoverr.AI and be remitted to your Delivery Partner, after Discoverr.AI takes a portion of the fee for allowing you to use it’s technological Services (the Brang Fee)." />
                        </ListItem>

                    </List>


                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '0', "& .MuListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            // color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                            fontWeight: 700
                        }
                    }}>
                        <ListItem sx={{
                            margin: 0, display: 'list-item', padding: { xs: "0" },
                            '& span': {
                                fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                            }
                        }}>
                            <ListItemText primary="If there is any error in the processing of the fees contained in this clause then:" />
                        </ListItem>

                        <ListItem sx={{
                            margin: 0, display: 'list-item', padding: { xs: "0" }, "& span": {
                                fontWeight: '400 !important'
                            }
                        }}>
                            <ListItemText primary="You acknowledge that Discoverr.AI has the right to reimbursement from you." />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Table sx={{
                        border: '1px solid #dee2e6', fontSize: "15px",
                        fontWeight: "bold",
                        marginBottom: "20px"
                    }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{
                                    textAlign: "center", background: '#000',
                                    padding: '15px',
                                    textAlign: 'center',
                                    fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                                    color: '#fff',
                                }} colSpan={12}>Brang additional Fees and Charges</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{
                            "& td": {
                                border: "1px solid #dee2e6",
                                textAlign: "center",
                                fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                                fontWeight: "600"
                            },
                            "tr": {
                                textAlign: "center",
                                fontSize: "15px",
                                fontWeight: "bold"
                            }
                        }}>
                            <TableRow>
                                <TableCell>Cancellation one day prior for a pre-booked delivery. </TableCell>
                                <TableCell sx={{
                                    fontWeight: "500 !important",
                                    fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' },
                                }}>$10</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cancellation with less than 4 hours' notice, yet more than 3hrs, for a same day or pre-booked delivery.	</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>50% of the run price.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cancellation with less than 1 hours' notice for a same day or pre-booked delivery.</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>Full charge of the package</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Re-schedule or Alteration of pre-booked delivery with less than 4hrs notice, yet no less than 1hrs notice.</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>$15.This will also be subject to driver's availability.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Alteration of booked delivery with more than 4hrs notice.  </TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>$10. This will also be subject to driver's availability.</TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>Urgent Hire / Express Delivery - minimum 1hrs notice.</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>$20. This will also be subject to availability of drivers close to the location</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Waiting charges for Driver after agreed pick-up time.</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>After the first 15 mins following the agreed pick-up time, $5 for every 5 minutes waiting time will be charged to the customer.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cost of the return of goods to store. </TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>Charges will be based on distance travelled at 50c per Km (Cost of return will be advised by Brang support team). Timing will be co-ordinated subject to driver's availability.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Package Weight above 10Kg</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>$5 surcharge applies for packages over 10kg up to 25kg</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pricing excludes tolls</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>When required tolls will incur an additional charge</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Public Holiday Surcharge. 	</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>15% on top of delivery fee excluding GST</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Hallmark Holiday Surcharge.</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>10% on top of delivery fee excluding GST</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Market Petrol Price Fluctuation.</TableCell>
                                <TableCell sx={{ fontWeight: "500 !important", fontSize: { xl: '16px !important', lg: '16px !important', md: '14px !important', sm: '14px !important', xs: '14px !important' }, }}>When market fuel prices exceed $2.00 per litre, a $1 per delivery surcharge applies</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={12}>Cancelled deliveries will not count towards the minimum number of required deliveries per Business Subscription tier.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={12}>Please note that Brang always strives to be on time, however due to traffic or unforeseen circumstances on rare occasions our Brangers may be late. Please keep a window of 60 minutes for your pick up time, in-case you have time bound deliveries. Please ensure your product is ready, prior to your allocated pick up time.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>10. PAYMENT GATEWAY</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{ margin: 0, }} primary="We use the payment platform called “Stripe” for all payment transactions on our website and app. Their terms and conditions can be found at https://stripe.com/au/ssa." />
                        </ListItem>
                    </List>
                </Box>



                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },

                    }}>11. TAX & GST</Typography>

                    <List sx={{
                        margin: 0, padding: 0, marginLeft: '40px', "& .MuiListItem-root": {
                            // listStyle: "disc"
                        },
                    }}>
                        <ListItem sx={{
                            margin: 0, listStyle: "disc", display: 'list-item', padding: { xs: "0" }, "& span": {
                                fontWeight: "400 !important"
                            }
                        }}>
                            <ListItemText primary="You must pay all applicable taxes;" />
                        </ListItem>

                        <ListItem sx={{ margin: 0,  display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText  sx={{                                
                                "& span": {
                                    // color: "#333333",
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700
                                }
                            }} primary="The following applies to the Technology Service Fee and all other amount payable to Discoverr.AI under this agreement:" />

                            <List sx={{
                                margin: 0, padding: 0, marginLeft: '15px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="You acknowledge that Discoverr.AI has the right to reimbursement from you." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="For the purposes of GST supplies to you the Services in sole consideration of the Technology Service Fee" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="All amounts payable are exclusive of GST;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="If GST is payable on a supply by Discoverr.AI you agree to pay that GST as an additional payment to Discoverr.AI." />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>12. INTELLECTUAL PROPERTY</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Discoverr.AI at all times retains ownership of all the intellectual property rights related to the Brang Website/app." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Any use of the Discoverr.AI Intellectual Property is subject to this agreement." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must not sell, copy, distribute, charge, license or lease any of the Discoverr.AI Intellectual Property;" />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must not reverse engineer or attempt to reverse engineer any of the Discoverr.AI Intellectual Property;" />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must not extract or attempt to extract the source code of any of the Discoverr.AI Intellectual Property." />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>13. LICENCE</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            // listStyle: "circle"
                        },
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', listStyle: "disc", padding: { xs: "0" } }}>
                            <ListItemText primary="Subject to compliance with this agreement, Discoverr.AI grants you a non-exclusive licence to use the Brang website/app for the Term of this agreement (the License);" />
                        </ListItem>
                        <ListItem sx={{
                            margin: 0, display: 'list-item', padding: { xs: "0" },
                            // listStyle: "circle",
                            "& span": {                                
                                fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                fontWeight:'700'
                            }
                        }}>
                            <ListItemText primary="License is not" />
                        </ListItem>
                        <List sx={{
                            margin: 0, padding: "0px", marginLeft: '15px', "& .MuiListItem-root": {
                                listStyle: "disc !important"
                            },
                            "& span": {
                                color: "#333333",
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                lineHeight: 1.8,
                            }
                        }}>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="Exclusive;" />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="Transferable;" />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="Assignable" />
                            </ListItem>
                        </List>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>14. CONFIDENTIALITY</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must not disclose Discoverr.AI’s Confidential Information to any third party except as necessary to perform this agreement." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Discoverr.AI must not disclose your Confidential Information to any third party except as necessary to perform this agreement." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Upon request a party must deliver up or destroy another parties Confidential Information that is in its possession." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="If a party to this agreement discloses Confidential Information to a third party it must ensure that third party treats that information as confidential." />
                        </ListItem>
                    </List>


                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>
                        <ListItem sx={{ margin: "0 !important", display: 'list-item', padding: { xs: "0" }, }}>
                            <ListItemText sx={{
                                "& span": {
                                    // color: "#333333",
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: "700"
                                }
                            }} primary="Despite anything to the contrary contained in this agreement a party to this agreement may disclose Confidential information if:" />
                        </ListItem>
                        <List sx={{
                            margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                listStyle: "disc !important"
                            },
                            "& span": {
                                color: "#333333",
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                lineHeight: 1.8,
                                fontWeight: 400,
                            }
                        }}>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="Authorised to do so by the party whose Confidential Information is to be disclosed;" />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="It is required by law" />
                            </ListItem>
                        </List>
                    </List>

                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>15. PRIVACY</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Discoverr.AI’s Privacy Policy is available at www.brang.com.au/privacy" />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Parties to this agreement must comply with all applicable Privacy and Data Protection Laws" />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Any Personal or Customer Information collected by each party must:" />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="If a party to this agreement discloses Confidential Information to a third party it must ensure that third party treats that information as confidential." />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '15px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Be used only for the purposes of carrying out this agreement;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Be maintained accurately;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Must not be copied or removed from the Brang website/app;" />
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You must immediately inform Discoverr.AI if you suspect or become aware of any unauthorised access by a third party to any Personal Information held be you." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="You agree that Discoverr.AI may use information it collects related to your transactions on the Brang website/app for the purposes of providing, enhancing, marketing and management of the Brang website/app." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Any user identification code, password or any other piece of information that form part of Discoverr.AI’s security procedures you must treat as Confidential Information and you must not disclose it to any third party. We reserve the right to disable any user identification code or password at any time. If you become aware or suspect that anyone other than you knows your user identification code or password, you must immediately notify us at support@Brang.com.au." />
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>16. WARRANTIES</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" }, }}>
                            <ListItemText primary="Each party warrants that:" sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700
                                }
                            }} />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="it has authority to enter into and perform its obligations and under this Agreement;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="will comply with all applicable Laws;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Must not be copied or removed from the Brang website/app;" />
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem sx={{
                            margin: 0, display: 'list-item', padding: 0, "& span": {
                                color: "#333333",
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                lineHeight: 1.8,
                                fontWeight: 400
                            }
                        }}>
                            <ListItemText primary="Except as required under the Australian Consumer Law, each party makes no representations and disclaims all warranties, express or implied, regarding its goods or services or any portion of them including any implied warranty of merchantability or fitness for a particular purpose and implied warranties arising from course of dealing or course of performance. This section does not limit liability or alter your rights that cannot be excluded under the Australian Consumer Law." />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>17. INDEMNITY</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700
                                }
                            }} primary="Each party (the Indemnifying Party) must indemnify, defend) and hold harmless the other party (the Indemnified Party) and their employees, agents, directors and officers from any claims, damages, losses and expenses (including legal costs) with respect to any third-party claim arising from or related to:" />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="the wilful misconduct or negligence or of the Indemnifying Party or its employees or agents;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="claims that the Indemnifying Party breached any of its representations and warranties contained in this Agreement;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="claims that the Indemnifying Party has infringed a third parties intellectual property rights;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="failure to comply with applicable law including privacy and data protection law by the Indemnifying Party" />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>18. LIMITATION OF LIABILITY</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="A party to this agreement will not be liable for any claim for any indirect, exemplary, or consequential damages, for loss of profits, or damages for loss of business or any third-party damages arising out of this Agreement." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="A party’s total cumulative liability of any kind under this Agreement will not exceed AU$100,000." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Clause 18(a) and 18(b) above do not apply to a breach of confidentiality or a party's indemnification obligations." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Clause 18(a) and 18(b) above do not (and are not intended to) override any rights that a party may under any applicable law, including the Australian Consumer Law." />
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>19. TERMINATION</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700
                                }
                            }} primary="A party to this agreement may terminate this agreement by providing notice via:" />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc !important"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="By Email" />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>20. VARIATION</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px',}}>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700
                                }
                            }} primary="Discoverr.AI may vary this agreement for time to time by;" />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc !important"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Providing 7 days' notice." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="If the new carried terms and condition are detrimental to your rights under this agreement providing 30 days' notice." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="publishing the varied terms and condition on the Brang website/app" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="By continuing to use the Brang website/app after the new varied agreement becomes effective you agree to be bound by the new varied terms and conditions." />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>21. GOVERNING LAW</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="This agreement is governed by the laws of Victoria the parties submit" />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="The Vienna Convention on the International Sale of Goods of 1980 does not apply." />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>22. RELATIONSHIP BETWEEN THE PARTIES</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>

                        <ListItem sx={{
                            margin: 0, display: 'list-item', padding: { xs: "0" }, "& span": {
                                fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                lineHeight: 1.8,
                                fontWeight: 700
                            }
                        }}>
                            <ListItemText primary="Nothing in this Agreement creates" />
                        </ListItem>
                        <List sx={{
                            margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                listStyle: "disc"
                            },
                            "& span": {
                                color: "#333333",
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                lineHeight: 1.8,
                                fontWeight: 400
                            }
                        }}>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="A joint venture;" />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="An agency relationship (apart from the agency relationship specified in clause 8);" />
                            </ListItem>
                            <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                <ListItemText primary="An employment relationship." />
                            </ListItem>
                        </List>
                        <ListItem sx={{
                            margin: 0, display: 'list-item',
                            padding: { xs: "0", },
                            "& span": {
                                fontWeight: 400,
                                fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            }
                        }}>
                            <ListItemText primary="Nothing in this agreement allows a party to enter into contracts, create legally binding relationships, incur debt or liability or incur expenses on behalf of the other party to this agreement." />
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>23. GENERAL</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                            listStyle: "disc"
                        },
                        "& span": {
                            color: "#333333",
                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                            lineHeight: 1.8,
                        }
                    }}>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Each party to this agreement bears sole responsibility for its employees and contractors." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="No failure to exercise, and no delay in exercising, any right, power or remedy under this agreement will operate as a waiver. No single or partial exercise of any right, power, or remedy will preclude any other, or further, exercise of that, or any other, right, power or remedy." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Any determination that a provision of this Agreement is invalid or unenforceable does not affect the remainder of this Agreement and the remainder will continue to have full force and effect." />
                        </ListItem>
                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="This Agreement contains the entire agreement between the parties with respect to its subject matter. It sets out the only conduct relied on by the parties and supersedes all earlier representations and conduct made by, or existing between, the parties with respect to its subject matter." />
                        </ListItem>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText primary="Nothing in this agreement should be taken to limit or exclude any rights you have under any applicable law that cannot be lawfully limited or excluded." />
                        </ListItem>
                    </List>


                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>

                        <ListItem sx={{
                            margin: 0, display: 'list-item', padding: { xs: "0" },
                        }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700,
                                }
                            }} primary="This Agreement may be executed in multiple counterparts, each of which will have the effect of an original. This Agreement may be entered into by:" />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400,
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Electronic signature;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Accepting the agreement using the Brand website/app" />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>

                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>24. DEFINTIONS</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700,
                                }
                            }} primary="Associate means an entity that directly or indirectly:" />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400,
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Controls a party;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Is under the Control of a party;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Is under the common Control of a party" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Australian Consumer Law means Schedule 2 of the Competition and Consumer Act 2010 (Cth)." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Confidential Information means any confidential, proprietary or other non-public information." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Commencement Date the date set at which you start using the Services." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Delivery Partner means independent contractors engaged by you as your agent for the purpose of delivering Goods." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Delivery Fee means the fee paid by you to your Delivery Partner agent." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Discoverr.AI Intellectual Property means all Intellectual Property owned by Discoverr.AI including the Brang website/app" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Goods any goods that you request your Delivery Partner to Deliver" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary='GST means the same as "GST" means in the A New Tax System (Goods and Services Tax) Act 1999 (Cth).' />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Intellectual Property Rights means all intellectual and industrial property rights (including underlying rights in any media now in existence or developed in the future) including, without limitation, rights in the nature of any patent, trade mark or service mark, copyright, visual image right, performance right, design, business name or trade secret or confidential information whether or not registered or registrable." />
                                </ListItem>

                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Privacy and Data Protection Laws means all applicable law imposing an obligation in relation to the collection, use, disclosure, storage or transmission of personal information including the Privacy Act 1988 (Cth)." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Services mean the services set out in clause 4" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Brang Fee means the fee payable for the use of the Brang website/app." />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Term mean the term of this Agreement." />
                                </ListItem>
                            </List>

                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                                    listStyle: "none"
                                },
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText sx={{
                                        "& span": {
                                            fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                            lineHeight: 1.8,
                                            fontWeight: '700'
                                        }
                                    }} primary="Personal Information means any information gathered in relation to this Agreement that:" />
                                    <List sx={{
                                        margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                            listStyle: "disc !important"
                                        },
                                        "& span": {
                                            color: "#333333",
                                            fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                            lineHeight: 1.8,
                                            fontWeight: 400,
                                        }
                                    }}>
                                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                            <ListItemText primary="relates to an identified or identifiable natural person;" />
                                        </ListItem>
                                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                            <ListItemText primary="can reasonably be used to identify an individual;" />
                                        </ListItem>
                                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                            <ListItemText primary="includes name, contact information, location information," />
                                        </ListItem>
                                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                            <ListItemText primary='may o be considered " personal data" or "personal information" under the applicable Privacy and Data Protections Law' />
                                        </ListItem>
                                    </List>
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>


                <Box sx={{ marginBottom: "15px", }}>
                    <Typography sx={{
                        padding: '0 0 10px 0',
                        color: '#000',
                        fontWeight: 700,
                        fontSize: { xl: '18px', lg: '18px', md: '16px', sm: '16px', xs: '16px' },
                    }}>25. INTERPRETATION</Typography>

                    <List sx={{
                        margin: 0, padding: "0px", marginLeft: '0px', "& .MuiListItem-root": {
                            listStyle: "none"
                        },
                    }}>

                        <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                            <ListItemText sx={{
                                "& span": {
                                    fontSize: { xl: '17px', lg: '17px', md: '15px', sm: '15px', xs: '15px' },
                                    lineHeight: 1.8,
                                    fontWeight: 700,
                                }
                            }} primary="The following rules of interpretation apply unless the context requires otherwise" />
                            <List sx={{
                                margin: 0, padding: "0px", marginLeft: '40px', "& .MuiListItem-root": {
                                    listStyle: "disc !important"
                                },
                                "& span": {
                                    color: "#333333",
                                    fontSize: { xl: '16px', lg: '16px', md: '14px', sm: '14px', xs: '14px' },
                                    lineHeight: 1.8,
                                    fontWeight: 400,
                                }
                            }}>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Headings are for convenience only and do not affect interpretation;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="The singular includes the plural and conversely;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Gender includes all genders;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="Where a word or phrase is defined, its other grammatical forms have a corresponding meaning;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to a person includes any body corporate, unincorporated body or other entity and conversely;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to a clause is to a clause of this agreement;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to any party to this agreement or any other agreement or document includes the party's successors and permitted assigns;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to any agreement or document is to that agreement or document as amended, notated, supplemented, varied or replaced from time to time, where applicable, in accordance with this agreement or that other agreement or document;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to any legislation or to any provision of any legislation includes any modification or re-enactment of it, any legislative provision substituted for it and all regulations and statutory instruments issued under it;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to dollars or $ is to Australian currency;" />
                                </ListItem>
                                <ListItem sx={{ margin: 0, display: 'list-item', padding: { xs: "0" } }}>
                                    <ListItemText primary="A reference to conduct includes, without limitation, any omissions, statements or undertakings, whether or not in writing." />
                                </ListItem>
                            </List>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            </Container>
        </Layout >
    )
}

export default TermsConditions
