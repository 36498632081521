import Layout from "../layout/Layout";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import google from "../../img/google-play.svg"
import apple from "../../img/apple-logo.svg"
import applesecond from "../../img/apple-logo-second.svg"
import { styled, css } from '@mui/system';
import { Swiper, SwiperSlide, } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import sliderImageOne from '../../img/branger-slider-img-1.jpg'
import sliderImageTwo from '../../img/branger-slider-img-2.jpg';
import sliderImageThree from '../../img/branger-slider-img-3.jpg';
import brangermoney from '../../img/branger-make-money.svg'
import crosshair from '../../img/crosshair.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useState } from "react";
import EditNoteIcon from '@mui/icons-material/EditNote';
import PaidIcon from '@mui/icons-material/Paid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { Helmet } from "react-helmet";
import MetaContainer from "../../seo/MetaContainer";

const SlickSlider = styled(Slider)({
    textDecoration: "none",
    color: '#000',
    fontWeight: '500',
    fontSize: '20px',
    paddingBottom: '80px',
    '& .slick-dots': {
        bottom: '15px !important',
    },
    // height: "calc(100vh - 230px) !important",

    '@media (max-width:1500px)': {
        // height: "calc(100vh - 170px) !important",
    },
    '@media (max-width:991px)': {
        // height: "auto !important",
    },

});

const Brangers = () => {
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
    };

    const redirectToNewURL = (name) => {
        if (name === "playstore") {
            window.open('https://play.google.com/store/apps/details?id=com.brang', '_blank');
        } else {
            window.open('https://testflight.apple.com/join/JddbRrMz', '_blank');
        }
    }

    const [activeIndex, setActiveIndex] = useState(0);

    const handleAfterChange = (index) => {
        setActiveIndex(index);
    };

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 3000,
        swipeToSlide: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const mainSettings = {
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: handleAfterChange,
        adaptiveHeight: true,
        lazyLoad: true,
        centerPadding: 0
    };

    return (
        <Layout>
            <MetaContainer
                title='Drivers - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/drivers'
            />
            <Box sx={{
                marginTop: { xl: '35px', lg: '20px', md: '20px', sm: '20px', xs: '30px' },
            }}>
                <SlickSlider className={activeIndex === 1 ? "nested_slider" : ""} {...mainSettings}
                    sx={{
                    }}>
                    <Container maxWidth='xl'>
                        <Box sx={{
                            marginTop: { xl: '0px', lg: '0px', md: "0px", sm: "20px", xs: "0px" }
                        }}>
                            <Box className="app-box" sx={{
                                backgroundColor: '#3789F0',
                                padding: { xl: '5px 50px', lg: '5px 30px', md: '5px 30px', sm: '5px 30px', xs: '20px 30px' },
                                borderRadius: { lg: '10px', md: '10px', sm: '10px', xs: '10px' },
                                // height: { xl: '220px', lg: '170px', md: '200px', sm: '230px', xs: 'inherit' },
                                gap: "14px",
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // flexDirection: 'column',
                                // textAlign: { xl: 'start', lg: 'start', md: 'center', sm: 'center', xs: 'center' }
                            }}>
                                <Typography sx={{
                                    color: '#FFFFFF',
                                    // fontSize: { xl: '35px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                    // lineHeight: { xl: '38px', lg: '30px', md: '30px', sm: '35px', xs: '30px' },
                                    // marginBottom: { xl: '20px', lg: '12px', md: '20px', sm: '20px', xs: '20px' },
                                    // '& br': {
                                    //     display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' },
                                    // }
                                }}>Download our app to access more features </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    // alignItems: 'center',
                                    gap: '14px',
                                    // justifyContent: { lg: 'start', md: 'center', sm: 'center', xs: 'center' }
                                }}>
                                    <Button sx={{
                                        background: '#FFFFFF !important',
                                        color: '#000000',
                                        borderRadius: '7px',
                                        textTransform: "capitalize",
                                        fontSize: { xl: '19px', lg: '15px', md: '16px', sm: '16px', xs: '11px' },
                                        padding: { xl: '9px 17px', lg: '5px 8px', md: '5px 8px', sm: '5px 8px', xs: '5px 8px' },
                                        height: '100%',
                                        '& img': {
                                            marginRight: { xl: '13px', lg: '10px', md: '10px', sm: '10px', xs: '10px' }
                                        },
                                        '& span': {
                                            display: 'none'
                                        }
                                    }}
                                        onClick={() => redirectToNewURL("playstore")}
                                    >
                                        <img src={google} />
                                        Playstore</Button>
                                    <Button
                                        sx={{
                                            background: '#FFFFFF !important',
                                            color: '#000000',
                                            borderRadius: '7px',
                                            textTransform: "capitalize",
                                            fontSize: { xl: '19px', lg: '16px', md: '16px', sm: '16px', xs: '11px' },
                                            padding: { xl: '9px 17px', lg: '5px 8px', md: '5px 8px', sm: '5px 8px', xs: '5px 8px' },
                                            height: '100%',
                                            '& img': {
                                                marginRight: { xl: '13px', lg: '10px', md: '10px', sm: '10px', xs: '10px' }
                                            },
                                            '& span': {
                                                display: 'none'
                                            }
                                        }}
                                        onClick={() => redirectToNewURL("app_store")}>
                                        <img src={apple} />
                                        Appstore</Button>
                                </Box>
                            </Box>
                            <Grid container spacing={3} sx={{
                                // marginBottom: '70px',
                                marginTop: '10px',
                            }}>
                                <Grid item xl={3} lg={3} md={12} >
                                    <Box sx={{
                                        width: { lg: '500px', md: 'inherit', sm: 'inherit', xs: 'inherit' },
                                        textAlign: { lg: 'start', md: 'center', sm: 'center', xs: 'center' },
                                        borderRadius: '30px'
                                    }}>
                                        <Typography variant="h2" sx={{
                                            fontSize: { xl: '36px', lg: '25px', md: '30px', sm: '30px', xs: '25px' },
                                            lineHeight: { xl: '51px', lg: '35px', md: '25px', sm: '25px', xs: '30px' },
                                            fontWeight: '500',
                                            marginBottom: { xl: '16px', lg: '16px', md: '20px', sm: '20px', xs: '20px' },
                                        }}>How can I make money on the BrangChain?</Typography>
                                        <Typography variant="h6"
                                            sx={{
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                fontWeight: '500',
                                                marginBottom: { xl: '20px', lg: '10px', md: '15px', sm: '15px', xs: '15px' },
                                                opacity: "0.5",
                                            }}
                                        > We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform.You will represent our company</Typography>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            lineHeight: '26px',
                                            fontWeight: '500',
                                            marginBottom: { xl: '20px', lg: '10px', md: '15px', sm: '15px', xs: '15px' },
                                        }}>As with all gig economy you are the boss of your time, however with BrangChain its slightly different, You will earn 30% more than the other delivery provider in addition of getting a carbon Tokens on each delivery which you can leverage with our partner network</Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            gap: '14px',
                                            justifyContent: { lg: 'start', md: 'center', sm: 'center', xs: 'center' }
                                        }}>
                                            <Button sx={{
                                                background: '#000000 !important',
                                                color: '#ffff',
                                                borderRadius: '7px',
                                                textTransform: "capitalize",
                                                fontSize: { xl: '19px', lg: '15px', md: '16px', sm: '16px', xs: '11px' },
                                                padding: { xl: '9px 17px', lg: '6px 15px', md: '5px 8px', sm: '5px 8px', xs: '5px 8px' },
                                                height: '100%',
                                                '& img': {
                                                    marginRight: { xl: '13px', lg: '10px', md: '10px', sm: '10px', xs: '10px' }
                                                },
                                                '& span': {
                                                    display: 'none'
                                                }
                                            }}
                                                onClick={() => redirectToNewURL("playstore")}
                                            >
                                                <img src={google} />
                                                Playstore</Button>
                                            <Button
                                                sx={{
                                                    background: '#000000 !important',
                                                    color: '#ffff',
                                                    borderRadius: '7px',
                                                    textTransform: "capitalize",
                                                    fontSize: { xl: '19px', lg: '16px', md: '16px', sm: '16px', xs: '11px' },
                                                    padding: { xl: '9px 17px', lg: '6px 15px', md: '5px 8px', sm: '5px 8px', xs: '5px 8px' },
                                                    height: '100%',
                                                    '& img': {
                                                        marginRight: { xl: '13px', lg: '10px', md: '10px', sm: '10px', xs: '10px' }
                                                    },
                                                    '& span': {
                                                        display: 'none'
                                                    }
                                                }}
                                                onClick={() => redirectToNewURL("app_store")}
                                            >
                                                <img src={applesecond} />
                                                App store</Button>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid container spacing={3} item xl={9} lg={9} md={12} sx={{
                                    justifyContent: { xl: 'end', lg: 'end', md: 'inherit', sm: 'center', xs: 'inherit' },
                                    marginTop: { xl: '0px', lg: '0px', md: '10px', sm: '10px', xs: '10px' },
                                }}>
                                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                                        <Box sx={{
                                            '& img': {
                                                width: '100%',
                                                height: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: '250px' },
                                                objectFit: 'cover',
                                                borderRadius: '15px',
                                            }
                                        }}>
                                            <img src={sliderImageOne} />
                                        </Box>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                                        <Box sx={{
                                            '& img': {
                                                width: '100%',
                                                height: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: '250px' },
                                                objectFit: 'cover',
                                                borderRadius: '15px',
                                            }
                                        }}>
                                            <img src={sliderImageTwo} />
                                        </Box>
                                    </Grid>
                                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                                        <Box sx={{
                                            '& img': {
                                                width: '100%',
                                                height: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: '250px' },
                                                objectFit: 'cover',
                                                borderRadius: '15px',
                                            }
                                        }}>
                                            <img src={sliderImageThree} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>

                    {/* <SwiperSlide>
                            <Box>
                                <Typography variant="h2" sx={{
                                    fontSize: { xl: '44px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                    lineHeight: { xl: '62px', lg: '35px', md: '30px', sm: '35px', xs: '30px' },
                                    marginBottom: { xl: '20px', lg: '15px', md: '20px', sm: '20px', xs: '20px' },
                                    color: '#000000',
                                    fontWeight: '500',
                                    '& br': {
                                        display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                                    }
                                }}>How can I Maximize my <br />earnings?</Typography>
                                <Typography sx={{
                                    fontSize: '16px',
                                    lineHeight: '23px',
                                    marginBottom: { xl: '20px', lg: '15px', md: '30px', sm: '20px', xs: '20px' },
                                    color: '#000000',
                                    fontWeight: '500',
                                    opacity: '0.5',
                                    '& br': {
                                        display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                                    }
                                }}>We are looking for a responsible Delivery Driver to distribute products promptly to our<br /> customers, using our platform. You will represent our company</Typography>

                                <Box sx={{
                                    position: 'relative',
                                    '& img': {
                                        width: '100%',
                                        height: { xl: 'calc(100vh - 360px)', lg: 'calc(100vh - 270px)', md: '100%', sm: '100%', xs: '500px' },
                                        objectFit: 'cover',
                                        borderRadius: { xl: '30px', lg: '30px', md: '20px', sm: '20px', xs: '15px' },
                                    }
                                }}>
                                    <img src={brangermoney} />
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 'auto',
                                        bottom: '30px',
                                        left: '20px',
                                    }}>
                                        <Typography sx={{
                                            fontSize: { xl: '40px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                            lineHeight: { xl: '55px', lg: '35px', md: '30px', sm: '35px', xs: '30px' },
                                            marginBottom: '10px',
                                            color: '#fff',
                                            fontWeight: '500',
                                            '& br': {
                                                display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                                            }
                                        }}>How can I make money on <br /> the BRANGCHAIN?</Typography>
                                        <Typography sx={{
                                            fontSize: '16px',
                                            lineHeight: { xl: '23px', lg: '23px', },
                                            color: '#fff',
                                            opacity: '0.5',
                                            '& br': {
                                                display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                                            }
                                        }} >We are looking for a responsible Delivery Driver to distribute products promptly to our <br />customers, using our platform. You will represent our company</Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </SwiperSlide> */}
                    <Container maxWidth='xl'>
                        <Box className="brangchain-driver" sx={{
                            marginTop: { xl: '0px', lg: '0px', md: "0px", sm: "30px", xs: "30px" }
                        }}>
                            <Box>
                                <Typography variant="h2" sx={{
                                    fontSize: { xl: '44px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                    lineHeight: { xl: '62px', lg: '35px', md: '30px', sm: '35px', xs: '30px' },
                                    marginBottom: { xl: '20px', lg: '15px', md: '20px', sm: '20px', xs: '20px' },
                                    color: '#000000',
                                    fontWeight: '500',

                                }}>Steps for becoming a BrangChain driver?</Typography>
                                <Typography sx={{
                                    fontSize: '16px',
                                    lineHeight: '23px',
                                    marginBottom: { xl: '20px', lg: '15px', md: '15px', sm: '15px', xs: '10px' },
                                    color: '#000000',
                                    fontWeight: '500',
                                    opacity: '0.5',
                                }}>Its easy, You can become our partner in just 4 steps</Typography>
                            </Box>
                            {/* <Slider {...settings} className="brangchain-driver-inner"
                                sx={{
                                    display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }
                                }}
                            > */}
                            <Grid container spacing={0} className="brangchain-driver-inner" sx={{
                                // marginBottom: { xl: '0px', lg: '0px', md: '50px', sm: '50px', xs: '0px' },
                            }}>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                    <Box sx={{
                                        overflow: 'hidden',
                                        transition: 'all 0.5s ease',
                                        borderRadius: '28px 30px',
                                        height: { xl: '330px', lg: '330px', md: '300px', sm: '300px', xs: 'auto' },
                                        marginTop: '20px',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}>
                                        <Box className='driver-detail' sx={{
                                            backgroundColor: '#3789F0',
                                            borderRadius: '28px 30px',
                                            margin: { xl: '0 20px', lg: '0 20px', md: '0 10px', sm: '0 10px', xs: '0 10px' },
                                            padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                            height: { xl: '250px', lg: '250px', md: '250px', sm: '250px', xs: '200px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'baseline',
                                            justifyContent: 'space-between',
                                            '& svg': {
                                                fontSize: { xl: '70px', lg: '70px', md: '70px', sm: '30px', xs: '30px' }, color: '#fff'
                                            }

                                        }}>
                                            {/* <img src={crosshair} /> */}
                                            <EditNoteIcon />
                                            <Box sx={{
                                                height: { xl: '140px', lg: '140px', md: 'auto', sm: 'auto', xs: 'auto' },
                                            }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                        lineHeight: '31px',
                                                        color: '#fff',
                                                        marginBottom: '10px',
                                                        fontWeight: '600',
                                                    }}
                                                >Step 1</Typography>
                                                <Typography sx={{
                                                    fontSize: { xl: '20px', lg: '18px', md: '18px', sm: '16px', xs: '16px' },
                                                    lineHeight: { xl: '33px', lg: '28px', md: '25px', sm: '25px', xs: '25px' },
                                                    color: '#fff',
                                                    height: '100px',
                                                }}>Register on BrangChain driver network</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                    <Box sx={{
                                        overflow: 'hidden',
                                        transition: 'all 0.5s ease',
                                        borderRadius: '28px 30px',
                                        height: { xl: '330px', lg: '330px', md: '300px', sm: '300px', xs: 'auto' },
                                        marginTop: '20px',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}>
                                        <Box className='driver-detail' sx={{
                                            backgroundColor: '#3789F0',
                                            borderRadius: '28px 30px',
                                            margin: { xl: '0 20px', lg: '0 20px', md: '0 10px', sm: '0 10px', xs: '0 10px' },
                                            padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                            height: { xl: '250px', lg: '250px', md: '250px', sm: '250px', xs: '200px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'baseline',
                                            justifyContent: 'space-between',
                                            '& svg': {
                                                fontSize: { xl: '70px', lg: '70px', md: '70px', sm: '30px', xs: '30px' }, color: '#fff'
                                            }
                                        }}>
                                            {/* <img src={crosshair} /> */}
                                            <CheckBoxIcon />
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                        lineHeight: '31px',
                                                        color: '#fff',
                                                        marginBottom: '10px',
                                                        fontWeight: '600',
                                                    }}
                                                >Step 2</Typography>
                                                <Typography sx={{
                                                    fontSize: { xl: '20px', lg: '18px', md: '18px', sm: '16px', xs: '16px' },
                                                    lineHeight: { xl: '33px', lg: '28px', md: '25px', sm: '25px', xs: '25px' },
                                                    color: '#fff',
                                                    height: '100px',
                                                }}>BrangChain will do your verification through KYC process</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                    <Box sx={{
                                        overflow: 'hidden',
                                        transition: 'all 0.5s ease',
                                        borderRadius: '28px 30px',
                                        height: { xl: '330px', lg: '330px', md: '300px', sm: '300px', xs: 'auto' },
                                        marginTop: '20px',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}>
                                        <Box className='driver-detail' sx={{
                                            backgroundColor: '#3789F0',
                                            borderRadius: '28px 30px',
                                            margin: { xl: '0 20px', lg: '0 20px', md: '0 10px', sm: '0 10px', xs: '0 10px' },
                                            padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                            height: { xl: '250px', lg: '250px', md: '250px', sm: '250px', xs: '200px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'baseline',
                                            justifyContent: 'space-between',
                                            '& svg': {
                                                fontSize: { xl: '70px', lg: '70px', md: '70px', sm: '30px', xs: '30px' }, color: '#fff'
                                            }
                                        }}>
                                            {/* <img src={crosshair} /> */}
                                            <PaidIcon />
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                        lineHeight: '31px',
                                                        color: '#fff',
                                                        marginBottom: '10px',
                                                        fontWeight: '600',
                                                    }}
                                                >Step 3</Typography>
                                                <Typography sx={{
                                                    fontSize: { xl: '20px', lg: '18px', md: '18px', sm: '16px', xs: '16px' },
                                                    lineHeight: { xl: '33px', lg: '28px', md: '25px', sm: '25px', xs: '25px' },
                                                    color: '#fff',
                                                    height: '100px',
                                                }}>Congratulations you have joined BrangChain, Start earning</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                    <Box sx={{
                                        overflow: 'hidden',
                                        transition: 'all 0.5s ease',
                                        borderRadius: '28px 30px',
                                        height: { xl: '330px', lg: '330px', md: '300px', sm: '300px', xs: 'auto' },
                                        marginTop: '20px',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}>
                                        <Box className='driver-detail' sx={{
                                            backgroundColor: '#3789F0',
                                            borderRadius: '28px 30px',
                                            margin: { xl: '0 20px', lg: '0 20px', md: '0 10px', sm: '0 10px', xs: '0 10px' },
                                            padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                            height: { xl: '250px', lg: '250px', md: '250px', sm: '250px', xs: '200px' },
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'baseline',
                                            justifyContent: 'space-between',
                                            '& svg': {
                                                fontSize: { xl: '70px', lg: '70px', md: '70px', sm: '30px', xs: '30px' }, color: '#fff'
                                            }
                                        }}>
                                            <AirportShuttleIcon />
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                        lineHeight: '31px',
                                                        color: '#fff',
                                                        marginBottom: '10px',
                                                        fontWeight: '600',
                                                    }}
                                                >Step 4</Typography>
                                                <Typography sx={{
                                                    fontSize: { xl: '20px', lg: '18px', md: '18px', sm: '16px', xs: '16px' },
                                                    lineHeight: { xl: '33px', lg: '28px', md: '25px', sm: '25px', xs: '25px' },
                                                    color: '#fff',
                                                    height: '100px',
                                                }}>Accept orders so you can make money on the run</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* <Box>
                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        margin: '0 10px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 5</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>ACCEPT ORDERS WHEN<br /> YOU CAN MAKE MONEY ON<br /> THE RUN</Typography>
                                        </Box>
                                    </Box>
                                </Box> */}
                        {/* </Slider> */}                        
                    </Box>
                </Container>
            </SlickSlider>



            {/* <Swiper
                                className="mySwiper"
                                spaceBetween={10}
                                centeredSlides={true}
                                slidesPerView={4}
                                loop={true}
                                autoplay={{
                                    delay: 100000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Autoplay, Pagination, Navigation]}>
                                <SwiperSlide>

                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box sx={{
                                            height: { xl: '140px', lg: '140px', md: 'auto', sm: 'auto', xs: 'auto' },
                                        }}>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 1</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>REGISTER ON BRANGCHAIN DRIVER <br />NETWORK</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>

                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 2</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>BRANGCHAIN WILL DO YOUR<br /> VERIFICATION THROUGH KYC<br /> PROCESS</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 3</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>CONGRATULATIONS YOU HAVE <br />JOINED BRANGHCHAIN,<br /> STARTING EARNING</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 4</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>ACCEPT ORDERS WHEN<br /> YOU CAN MAKE MONEY ON<br /> THE RUN</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 5</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>ACCEPT ORDERS WHEN<br /> YOU CAN MAKE MONEY ON<br /> THE RUN</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 6</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>ACCEPT ORDERS WHEN<br /> YOU CAN MAKE MONEY ON<br /> THE RUN</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>      <SwiperSlide>
                                    <Box sx={{
                                        backgroundColor: '#3789F0',
                                        borderRadius: '28px 30px',
                                        padding: { xl: '32px', lg: '30px', md: '25px', sm: '25px', xs: '20px' },
                                        height: { xl: '375px', lg: '250px', md: '300px', sm: '250px', xs: '200px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        justifyContent: 'space-between',
                                    }}>
                                        <img src={crosshair} />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xl: '25px', lg: '23px', md: '22px', sm: '22px', xs: '22px' },
                                                    lineHeight: '31px',
                                                    color: '#fff',
                                                    marginBottom: '10px',
                                                    fontWeight: '600',
                                                }}
                                            >Step 7</Typography>
                                            <Typography sx={{
                                                fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '16px', xs: '16px' },
                                                lineHeight: { xl: '33px', lg: '33px', md: '25px', sm: '25px', xs: '25px' },
                                                color: '#fff',
                                                '& br': {
                                                    display: { xl: 'block', lg: 'none', md: 'none', sm: 'none', xs: 'none' },
                                                }
                                            }}>ACCEPT ORDERS WHEN<br /> YOU CAN MAKE MONEY ON<br /> THE RUN</Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            </Swiper> */}
            {/* </SwiperSlide>
                </StyledSwiper> */}
        </Box>

        </Layout >
    )
};

export default Brangers;